import { acquireToken as _acquireToken, Api, Security, SecurityProviders } from 'kes-common';

import auth0Client from '@/authentication/auth0';
import msalInstance from '@/authentication/msal';
import { backendUrl, MSAL_CLIENT_ID, TEST_USERS_ENABLED } from '@/constants';
import { testUsers } from '@/components/TestUserSelector/users';

const singleton = new Api(backendUrl);

type BearerAuth = Security<'BearerAuth', never>;

type ApiParams = readonly any[];

interface Options {
	cache?: Request['cache'];
	headers?: Record<string, string>;
}

export const acquireToken = () =>
	_acquireToken({
		auth0Client,
		hasTestUsersEnabled: TEST_USERS_ENABLED,
		msalClientId: MSAL_CLIENT_ID,
		msalInstance,
	});

function withAuthentication<T extends ApiParams, R extends any>(
	apiCall: (sec: BearerAuth, ...args: [...T, Options]) => Promise<R>,
	callOptions: Partial<Options> = {},
): (...args: T) => Promise<R> {
	async function withAuthenticationInner(...args: T): Promise<R> {
		const options: Options = {};
		if (TEST_USERS_ENABLED) {
			// push the options argument as the last one in the args
			options.headers = {
				'Test-User-Id': localStorage.getItem('Test-User-Id') || testUsers[0].id,
			};
		}
		const accessToken = await acquireToken();

		const security = SecurityProviders.BearerAuth(accessToken);
		return apiCall(security, ...args, { ...callOptions, ...options });
	}
	return withAuthenticationInner;
}

export const assistFeedback = withAuthentication(singleton.inspectionPostFeedback.bind(singleton));

export const businessLinesGet = withAuthentication(
	singleton.studyListBusinessLines.bind(singleton),
);

export const masterTemplateCreate = withAuthentication(
	singleton.mastertemplateCreate.bind(singleton),
);
export const masterTemplateDelete = withAuthentication(
	singleton.mastertemplateDelete.bind(singleton),
);
export const masterTemplatesGet = withAuthentication(singleton.mastertemplateLists.bind(singleton));

export const labelsGet = withAuthentication(singleton.studyReadLabels.bind(singleton));

export const projectOverview = withAuthentication(singleton.projectGet.bind(singleton));
export const projectCreate = withAuthentication(singleton.projectPost.bind(singleton));
export const projectDetailsUpdate = withAuthentication(
	singleton.projectPutWithidDetails.bind(singleton),
);
export const projectDelete = withAuthentication(singleton.projectDeleteWithid.bind(singleton));
export const projectCopy = withAuthentication(singleton.projectPostWithidCopy.bind(singleton));
export const projectGetWithid = withAuthentication(singleton.projectGetWithid.bind(singleton));
export const projectIndustriesGet = withAuthentication(
	singleton.projectGetIndustries.bind(singleton),
);

export const projectReportOptionsUpdate = withAuthentication(
	singleton.projectPutWithidReportSettings.bind(singleton),
);
export const projectReportsDownload = withAuthentication(
	singleton.projectGetWithidDownloadReports.bind(singleton),
);

export const projectUsersGet = withAuthentication(singleton.projectuserReads.bind(singleton));
export const projectUserCreate = withAuthentication(
	singleton.projectuserCreateProjectRole.bind(singleton),
);
export const projectUserDelete = withAuthentication(
	singleton.projectuserDeleteProjectRole.bind(singleton),
);

export const servicesGet = withAuthentication(singleton.studyListServices.bind(singleton));

export const studiesGet = withAuthentication(singleton.studyListStudies.bind(singleton));
export const studyCopy = withAuthentication(singleton.studyCopy.bind(singleton));
export const studyCreate = withAuthentication(singleton.studyCreate.bind(singleton));
export const studyExport = withAuthentication(singleton.studyExportTemplate.bind(singleton));
export const studyGet = withAuthentication(singleton.studyRead.bind(singleton));
export const studyGetVersions = withAuthentication(singleton.templateLists.bind(singleton));
export const studyImport = withAuthentication(singleton.studyImportTemplate.bind(singleton));
export const studyPublish = withAuthentication(singleton.templatePublish.bind(singleton));
export const studyRoleAdd = withAuthentication(singleton.studyuserCreateStudyRole.bind(singleton));
export const studyRoleDelete = withAuthentication(
	singleton.studyuserDeleteStudyRole.bind(singleton),
);
export const studyRoleUpdate = withAuthentication(
	singleton.studyauthorizationUpdateStudyRole.bind(singleton),
);
export const studyUpdate = withAuthentication(singleton.studyUpdate.bind(singleton));

export const inspectionComplete = withAuthentication(
	singleton.inspectionPutComplete.bind(singleton),
);

export const inspectionExternalUrls = withAuthentication(
	singleton.inspectionAddExternalUrl.bind(singleton),
);
export const inspectionRolesGet = withAuthentication(singleton.inspectionuserReads.bind(singleton));
export const inspectionRoleCreate = withAuthentication(
	singleton.inspectionuserCreateInspectionRole.bind(singleton),
);
export const inspectionRoleDelete = withAuthentication(
	singleton.inspectionuserDeleteInspectionRole.bind(singleton),
);

export const publishedStudiesGet = withAuthentication(
	singleton.studyListPublishedStudies.bind(singleton),
);

export const myInspectionsList = withAuthentication(singleton.inspectionLists.bind(singleton));

export const inspectionGet = withAuthentication(singleton.inspectionGetWithid.bind(singleton));
export const inspectionsRequest = withAuthentication(
	singleton.projectAddInspectionsTo.bind(singleton),
);

export const projectInspectionCopy = withAuthentication(
	singleton.projectPostWithidStudyWithStudyidVersionWithVersionCopy.bind(singleton),
);

export const inspectionUpdate = withAuthentication(
	singleton.inspectionPutDescription.bind(singleton),
);
export const inspectionDelete = withAuthentication(
	singleton.inspectionDeleteWithid.bind(singleton),
);

export const inspectionPropertyRelatedFileDelete = withAuthentication(
	singleton.relatedfileDeleteInspectionWithInspectionidPropertyWithPropertyidFile.bind(singleton),
);

export const inspectionRelatedFileDelete = withAuthentication(
	singleton.relatedfileDeleteFilewithQuestionOrActivityOrProject.bind(singleton),
);

export const inspectionRelatedFileGet = withAuthentication(
	singleton.relatedfileGetFileWithProjectOrInspection.bind(singleton),
);

export const inspectionAssetPropertyRelatedFileDelete = withAuthentication(
	singleton.relatedfileDeleteInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetidFile.bind(
		singleton,
	),
);

export const inspectionPropertyRelatedFileUpload = withAuthentication(
	singleton.relatedfilePostInspectionWithInspectionidPropertyWithPropertyidFile.bind(singleton),
);

export const inspectionAssetPropertyRelatedFileUpload = withAuthentication(
	singleton.relatedfilePostInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetidFile.bind(
		singleton,
	),
);

export const relatedFileUpload = withAuthentication(
	singleton.relatedfilePostFileWithProjectOrInspectionOrProperty.bind(singleton),
);

export const datasetPostSubstance = withAuthentication(
	singleton.datasetPostInspectionWithInspectionidSubstance.bind(singleton),
);
export const datasetPostTank = withAuthentication(
	singleton.datasetPostInspectionWithInspectionidTank.bind(singleton),
);
export const datasetPostAssets = withAuthentication(
	singleton.datasetPostInspectionWithInspectionidCsvWithAssettypeid.bind(singleton),
);
export const datasetDeleteSubstance = withAuthentication(
	singleton.datasetDeleteInspectionWithInspectionidSubstance.bind(singleton),
);
export const datasetDeleteTank = withAuthentication(
	singleton.datasetDeleteInspectionWithInspectionidTank.bind(singleton),
);
export const datasetDeleteAssets = withAuthentication(
	singleton.assetDeleteInspectionWithInspectionidTypeWithtypeid.bind(singleton),
);
export const answerUpsertForRepeatingAsset = withAuthentication(
	singleton.assetPostInspectionWithInspectionidAnswerRepeatingWithid.bind(singleton),
);
export const answerUpsertForSingularAsset = withAuthentication(
	singleton.assetPostInspectionWithInspectionidAnswerSingular.bind(singleton),
);
export const projectContactDetailsPut = withAuthentication(
	singleton.projectPutWithidDetails.bind(singleton),
);

export const assetUpsert = withAuthentication(
	singleton.assetPostInspectionWithInspectionidTypeWithtypeidWithid.bind(singleton),
);

export const assetDelete = withAuthentication(
	singleton.assetDeleteInspectionWithInspectionidTypeWithtypeidWithid.bind(singleton),
);

export const getUserProfile = withAuthentication(singleton.userGetprofile.bind(singleton), {
	cache: 'no-cache',
});

export const updateUserFromActiveDirectory = withAuthentication(
	singleton.userPutProfileAdFields.bind(singleton),
);

export const getUserProfileExtended = withAuthentication(
	singleton.userGetWithidProfileDetails.bind(singleton),
);

export const updateUserProfile = withAuthentication(
	singleton.userPutWithidProfileDetails.bind(singleton),
);

export const postImageForRepeatingAsset = withAuthentication(
	singleton.imagePostInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetid.bind(
		singleton,
	),
);
export const postImageForSingularAsset = withAuthentication(
	singleton.imagePostInspectionWithInspectionidPropertyWithPropertyid.bind(singleton),
);
export const getImageForSingularAsset = withAuthentication(
	singleton.imageGetInspectionWithInspectionidPropertyWithPropertyid.bind(singleton),
);
export const getImageForRepeatingAsset = withAuthentication(
	singleton.imageGetInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetid.bind(
		singleton,
	),
);
export const deleteImageForSingularAsset = withAuthentication(
	singleton.imageDeleteInspectionWithInspectionidPropertyWithPropertyid.bind(singleton),
);
export const deleteImageForRepeatingAsset = withAuthentication(
	singleton.imageDeleteInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetid.bind(
		singleton,
	),
);

export const flagCreateRow = withAuthentication(
	singleton.assetPostInspectionWithInspectionidFlagWithFlagidFlagWithFlagid.bind(singleton),
);

export const inspectionImageUpdateCaptionRepeating = withAuthentication(
	singleton.imagePostInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetidcaption.bind(
		singleton,
	),
);
export const inspectionImageUpdateCaption = withAuthentication(
	singleton.imagePostInspectionWithInspectionidPropertyWithPropertyidcaption.bind(singleton),
);

export const inspectionScriptFileDelete = withAuthentication(
	singleton.inspectionDeleteScript.bind(singleton),
);

export const inspectionScriptFileRun = withAuthentication(
	singleton.inspectionRunScript.bind(singleton),
);

export const inspectionScriptFileUpload = withAuthentication(
	singleton.inspectionUploadScript.bind(singleton),
);

export const templateScriptFileUpload = withAuthentication(
	singleton.studyUploadScript.bind(singleton),
);

export const templateScriptFileDelete = withAuthentication(
	singleton.studyDeleteScript.bind(singleton),
);

export const knowledgeGroupsGet = withAuthentication(
	singleton.studyGetKnowledgeGroups.bind(singleton),
);

export const timezonesGet = withAuthentication(singleton.userGetTimeZoneIds.bind(singleton));

export const getProjectSubjects = withAuthentication(singleton.projectGetSubjects.bind(singleton));

export const getTemplatesBySubject = withAuthentication(
	singleton.projectGetSubjectWithSubjectidTemplate.bind(singleton),
);

export const getTemplateLanguages = withAuthentication(
	singleton.studyListLanguages.bind(singleton),
);

export const studyDelete = withAuthentication(singleton.studyDelete.bind(singleton));

export const studyReadReportTemplate = withAuthentication(
	singleton.studyReadReportTemplate.bind(singleton),
);

export const createAccessToken = withAuthentication(
	singleton.projectGetWithidToken.bind(singleton),
);

export const fetchAccessString = withAuthentication(
	singleton.projectGetWithidAccessstring.bind(singleton),
);

export const deleteAssetsAndImagesByInspectionId = withAuthentication(
	singleton.assetClearInspection.bind(singleton),
);

export const inspectionAssist = withAuthentication(singleton.inspectionPostAssist.bind(singleton));

export const getRelatedActivities = withAuthentication(
	singleton.relatedactivityGet.bind(singleton),
);

export const relatedActivityCreate = withAuthentication(
	singleton.relatedactivityCreate.bind(singleton),
);

export const relatedActivityDelete = withAuthentication(
	singleton.relatedactivityDelete.bind(singleton),
);

export const relatedActivityRegenerateReport = withAuthentication(
	singleton.relatedactivityRegenerateReport.bind(singleton),
);

export const getProjectListAndInspections = withAuthentication(
	singleton.projectListsAndInspections.bind(singleton),
);

export const inspectionExcelDownload = withAuthentication(
	singleton.inspectionExcelDownload.bind(singleton),
);
