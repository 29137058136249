/* eslint-disable indent, max-len, @typescript-eslint/indent */
// THIS IS A GENERATED FILE, DO NOT MODIFY
// Boilerplate:

interface CancelablePromise<T> extends Promise<T> {
	'@@redux-saga/CANCEL_PROMISE': () => void;
}

function makeCancelablePromise<T>(promise: Promise<T>, onCancel: () => void): CancelablePromise<T> {
	const castedPromise = promise as CancelablePromise<T>;
	castedPromise['@@redux-saga/CANCEL_PROMISE'] = onCancel;
	return castedPromise;
}

export class FetchResponse<S extends number, R> {
	public status: S;
	public result: R;
	public url: Response['url'];
	public type: Response['type'];
	public headers: Response['headers'];
	public statusText: Response['statusText'];
	public redirected: Response['redirected'];
	public ok: S extends 200 ? true : S extends 201 ? true : S extends 204 ? true : false;
	public constructor(response: Response, status: S, result: R) {
		this.status = status;
		this.result = result;
		this.url = response.url;
		this.type = response.type;
		this.headers = response.headers;
		this.statusText = response.statusText;
		this.redirected = response.redirected;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.ok = (status === 200 || status === 201 || status === 204) as any;
	}
	public expectSuccess(): S extends 200 ? R : S extends 201 ? R : S extends 204 ? R : never {
		if (this.ok) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return this.result as any;
		}
		const stringifiedResponse = JSON.stringify(this.result, null, 2);
		throw new Error(`Response was not OK.\nResponse body:\n${stringifiedResponse}`);
	}
	public expect<E extends S>(code: E | E[]): S extends E ? R : never {
		if (Array.isArray(code) ? (code as number[]).includes(this.status) : this.status === code) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return this.result as any;
		}
		const stringifiedResponse = JSON.stringify(this.result, null, 2);
		throw new Error(
			`Expected HTTP status code to be ${code}, but it was ${this.status}.\nResponse body:\n${stringifiedResponse}`
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
function toJson(response: Response): Promise<any> {
	return response.json();
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function toResponse(response: Response): Promise<Response> {
	return Promise.resolve(response);
}

export interface ResolvedSecurity {
	updateUrl(url: string): string;
	updateHeaders(headers: Record<string, string>): Record<string, string>;
}
export interface Security<N extends string, S extends string> extends ResolvedSecurity {
	readonly name: N;
	readonly scope: S[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class HttpAuthentication<N extends string> implements Security<N, never> {
	public readonly name: N;
	public readonly scope: never[] = [];
	public readonly token: string;
	public readonly schema: string;
	public constructor(name: N, token: string, schema: string) {
		this.name = name;
		this.token = token;
		this.schema = schema;
	}
	public updateUrl(url: string): string {
		return url;
	}
	public updateHeaders(headers: Record<string, string>): Record<string, string> {
		return {
			...headers,
			'Authorization': `${this.schema} ${this.token}`,
		};
	}
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ApiKeyAuthentication<N extends string> implements Security<N, never> {
	public readonly name: N;
	public readonly scope: never[] = [];
	public readonly token: string;
	public readonly key: string;
	public readonly in: 'query' | 'header' | 'cookie';
	public constructor(name: N, inType: 'query' | 'header' | 'cookie', key: string, token: string) {
		this.name = name;
		this.token = token;
		this.in = inType;
		this.key = key;
	}
	public updateUrl(url: string): string {
		if (this.in === 'query') {
			const arg = `${encodeURIComponent(this.key)}=${encodeURIComponent(this.token)}`;
			if (url.includes('?')) {
				return `${url}&${arg}`;
			} else {
				return `${url}?${arg}`;
			}
		}
		return url;
	}
	public updateHeaders(headers: Record<string, string>): Record<string, string> {
		if (this.in === 'header') {
			return {
				...headers,
				[this.key]: this.token,
			};
		}
		return headers;
	}
}

const VoidSecurity: ResolvedSecurity = {
	updateUrl(url): string {
		return url;
	},
	updateHeaders(headers): Record<string, string> {
		return headers;
	},
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function combinedSecurity<S extends Record<string, ResolvedSecurity>>(sec: S): ResolvedSecurity {
	const array = Object.values(sec);
	switch (array.length) {
		case 0:
			return VoidSecurity;
		case 1:
			return array[0]!;
		default: return {
			updateUrl(url): string {
				for (const security of array) {
					url = security.updateUrl(url);
				}
				return url;
			},
			updateHeaders(headers): Record<string, string> {
				for (const security of array) {
					headers = security.updateHeaders(headers);
				}
				return headers;
			},
		};
	}
}

type ObjectValues<O> = O[keyof O];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Unpromisify<F extends (...args: any[]) => Promise<any>> = F extends (...args: []) => Promise<infer R> ? R : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseMapToReturnType<R extends {[key: number]: (...args: any[]) => Promise<any>}> =
	ObjectValues<{ [K in keyof R]: K extends number ? FetchResponse<K, Unpromisify<R[K]>> : never }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
type JsonAny = any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function apiRequest<R extends {[key: number]: (response: Response) => Promise<any>}>(
	request: Request,
	statusCodes: R,
): CancelablePromise<ResponseMapToReturnType<R>> {
	const controller = new AbortController();
	const newRequest = new Request(request, {
		signal: controller.signal,
		credentials: 'omit',
	});
	return makeCancelablePromise(fetch(newRequest).then((response): Promise<ResponseMapToReturnType<R>> => {
		const status = response.status;
		const parser = statusCodes[status];
		if (!parser) {
			throw new Error(`Undocumented HTTP status code: ${status}`);
		}
		return parser(response).then((decoded): ResponseMapToReturnType<R> => {
			const result = new FetchResponse(response, status, decoded);
			return result as ResponseMapToReturnType<R>;
		});
	}), (): void => controller.abort());
}

interface Options {
	cache?: Request['cache'];
	headers?: Record<string, string>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objectToQueryString(input: Record<string, string | number | boolean | null | undefined>): string {
	const entries = Object.entries(input).filter((entry): entry is [string, string | number | boolean] => entry[1] !== null && entry[1] !== undefined);
	if (entries.length === 0) {
		return '';
	}
	return `?${entries
		.map(([key, value]): string => `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`).join('&')}`;
}

// Types:
// DEFINITION: ApiPublishedStudyDto
export interface ApiPublishedStudyDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	assetLibraryVersion: number;
	businessLineNames: string[];
	isPrivate: boolean;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	number: number;
	labels: string[];
	lastPublished: string;
	description: string | null;
	type: 'CLASSIC' | 'WORD';
}
// DEFINITION: ApiServiceDto
export interface ApiServiceDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	serviceName: string;
	marketName: string | null;
}
// DEFINITION: ApiStudyDto
export interface ApiStudyDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	description: string | null;
	sourceUrl: string | null;
	businessLineNames: string[];
	isPrivate: boolean;
	labels: string[];
	users: ApiStudyUserDto[];
	subjects: ApiSubject[];
	services: ApiServiceDto[];
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	draftVersion: number;
	lastPublishedDate: string | null;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	useCount: number;
	language: string;
	allowScript: boolean;
	/**
	 * This should be in the following format: uuid
	 */
	lastPublishedAssetLibrary: string | null;
	file: string | null;
	type: 'CLASSIC' | 'WORD';
	sharepointUrl: string | null;
	showNotAnsweredText: boolean;
}
// DEFINITION: ApiStudyUserDto
export interface ApiStudyUserDto {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('STUDY_ADMINISTRATOR' | 'STUDY_SUGGESTED_INSPECTOR')[];
	globalAdmin: boolean;
	name: string | null;
	email: string;
}
// DEFINITION: ApiSubject
export interface ApiSubject {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
}
// DEFINITION: ApiCreateStudyDto
export interface ApiCreateStudyDto {
	name: string;
	description: string | null;
	sourceUrl: string | null;
	businessLineNames: string[];
	isPrivate: boolean;
	labels: string[];
	subjects: string[];
	services: string[];
	language: string;
	allowScript: boolean | null;
	type: 'CLASSIC' | 'WORD';
	/**
	 * This should be in the following format: uuid
	 */
	masterTemplateId: string | null;
	showNotAnsweredText: boolean | null;
}
// DEFINITION: ApiError
export interface ApiError {
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	statusCode: number;
	message: string;
	details: string[];
	validationError: boolean;
}
// DEFINITION: ApiActionDto
export interface ApiActionDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	propertyId: string;
	/**
	 * This should be in the following format: uuid
	 */
	rule: string;
	check: string;
	dependentPropertyIds: string[];
}
// DEFINITION: ApiAssetFilter
export interface ApiAssetFilter {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	propertyId: string;
	name: string;
	checkType: 'MIN' | 'MAX' | 'EQUALS' | 'RANGE';
	checkValue: number | null;
	upperBound: number | null;
}
// DEFINITION: ApiAssetLibrary
export interface ApiAssetLibrary {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	studyName: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	version: number;
	/**
	 * This should be in the following format: uuid
	 */
	studyId: string;
	categoryIds: string[];
	categoriesById: {
		[key: string]: ApiCategory;
	};
	assetTypesById: {
		[key: string]: ApiAssetType;
	};
	propertiesById: {
		[key: string]: ApiProperty;
	};
	choicesById: {
		[key: string]: ApiChoice;
	};
	ruleSetsById: {
		[key: string]: ApiRuleSetDto;
	};
	actionsById: {
		[key: string]: ApiActionDto;
	};
	assetFiltersById: {
		[key: string]: ApiAssetFilter;
	};
	featureMilicense: boolean;
	featureRedFlag: boolean;
	featureNotes: boolean;
	language: 'EN' | 'NL';
}
// DEFINITION: ApiAssetType
export interface ApiAssetType {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	isDefault: boolean;
	/**
	 * This should be in the following format: uuid
	 */
	stableId: string;
	name: string;
	propertyIds: string[];
	repeating: boolean;
	fixed: boolean;
	survey: boolean;
	type: 'SURVEY' | 'FLAG' | 'FORM';
	/**
	 * This should be in the following format: uuid
	 */
	redFlagPropertyId: string | null;
	assetFilterIds: string[];
	info: string | null;
}
// DEFINITION: ApiBuilderProject
export interface ApiBuilderProject {
	/**
	 * This should be in the following format: uuid
	 */
	studyId: string;
	assetLibrary: ApiAssetLibrary;
	reportTemplate: ApiReportTemplate;
	/**
	 * This should be in the following format: uuid
	 */
	testProjectId: string;
	endNote: string | null;
	study: ApiStudyDto | null;
}
// DEFINITION: ApiCategory
export interface ApiCategory {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	isDefault: boolean;
	/**
	 * This should be in the following format: uuid
	 */
	stableId: string;
	name: string;
	assetTypeIds: string[];
	type: 'SURVEY' | 'FLAG' | 'FORM';
	fixed: boolean;
	info: string | null;
}
// DEFINITION: ApiChoice
export interface ApiChoice {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	stableId: string;
	name: string;
	description: string | null;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number | null;
	reportText: string | null;
	computed: boolean;
	dependentPropertyIds: string[];
	reportTextSource: 'ANSWER' | 'ALTERNATE' | 'FALSE';
}
// DEFINITION: ApiCopyStudy
export interface ApiCopyStudy {
	study: ApiStudyDto;
	builderProject: ApiBuilderProject | null;
}
// DEFINITION: ApiProperty
export interface ApiProperty {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	stableId: string;
	name: string;
	placeholder: string;
	type: 'STRING' | 'DECIMAL' | 'SINGLE_SELECT' | 'MULTI_SELECT' | 'SINGLE_SUBSTANCE' | 'MULTI_SUBSTANCE' | 'SINGLE_ASSET_REFERENCE' | 'MULTI_ASSET_REFERENCE' | 'IMAGE' | 'LOCATIONS' | 'DATE' | 'RICH_TEXT';
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number | null;
	question: string | null;
	choiceIds: string[] | null;
	description: string | null;
	fixed: boolean;
	inspection: boolean;
	survey: boolean;
	allowMultipleAnswers: boolean;
	hasNotApplicableOption: boolean;
	hasOtherOption: boolean;
	caption: string | null;
	dependentCombinator: 'ANY' | 'ALL';
	sourceUrl: string;
	parentChoiceIds: string[];
	parentActionIds: string[];
	/**
	 * This should be in the following format: uuid
	 */
	referencedAssetType: string | null;
	/**
	 * This should be in the following format: uuid
	 */
	identifyingProperty: string | null;
	ruleIds: string[];
	required: boolean;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	width: number | null;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	height: number | null;
	dateFormat: string | null;
}
// DEFINITION: ApiReportTemplate
export interface ApiReportTemplate {
	name: string;
	template: string;
	updatedAt: string;
	/**
	 * This should be in the following format: uuid
	 */
	latestUpdateClientId: string | null;
}
// DEFINITION: ApiRuleDto
export interface ApiRuleDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	description: string;
	checkType: 'MIN' | 'MAX' | 'EQUALS' | 'RANGE';
	checkValue: number;
	upperBound: number | null;
	check: string | null;
}
// DEFINITION: ApiRuleSetDto
export interface ApiRuleSetDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	rules: ApiRuleDto[];
}
// DEFINITION: ApiStudyReportTemplate
export interface ApiStudyReportTemplate {
	reportText: string;
}
// DEFINITION: ApiCreateForm
export interface ApiCreateForm {
	name: string;
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
}
// DEFINITION: ApiField
export interface ApiField {
	/**
	 * This should be in the following format: uuid
	 */
	formId: string;
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number;
	type: 'STRING' | 'DECIMAL' | 'SINGLE_SELECT' | 'MULTIPLE_SELECT' | 'IMAGE';
	choices: ApiFieldChoice[];
}
// DEFINITION: ApiFieldChoice
export interface ApiFieldChoice {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number;
}
// DEFINITION: ApiForm
export interface ApiForm {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number;
	fields: ApiField[];
}
// DEFINITION: ApiCreateOrUpdateField
export interface ApiCreateOrUpdateField {
	/**
	 * This should be in the following format: uuid
	 */
	formId: string;
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	type: 'STRING' | 'DECIMAL' | 'SINGLE_SELECT' | 'MULTIPLE_SELECT' | 'IMAGE';
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number;
}
// DEFINITION: ApiReOrderDto
export interface ApiReOrderDto {
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number;
}
// DEFINITION: ApiCreateOrUpdateFieldChoice
export interface ApiCreateOrUpdateFieldChoice {
	/**
	 * This should be in the following format: uuid
	 */
	fieldId: string;
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
}
// DEFINITION: ApiKnowledgeGroup
export interface ApiKnowledgeGroup {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	businessLine: string | null;
}
// DEFINITION: ApiAsset
export interface ApiAsset {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	assetTypeId: string;
	valuesByPropertyId: {
		[key: string]: ApiValue;
	};
	filesByPropertyId: {
		[key: string]: ApiFile[];
	};
	notesByPropertyId: {
		[key: string]: string;
	};
	captionsByPropertyId: {
		[key: string]: string;
	};
}
// DEFINITION: ApiExternalUrl
export interface ApiExternalUrl {
	name: string;
	url: string;
	default: boolean | null;
}
// DEFINITION: ApiFlag
export interface ApiFlag {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	asset: string;
	/**
	 * This should be in the following format: uuid
	 */
	flagAsset: string;
	/**
	 * This should be in the following format: uuid
	 */
	flagAssetType: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number;
}
// DEFINITION: ApiFormRow
export interface ApiFormRow {
	/**
	 * This should be in the following format: uuid
	 */
	assetId: string;
	/**
	 * This should be in the following format: uuid
	 */
	formId: string;
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	values: ApiFormValue[];
}
// DEFINITION: ApiFormValue
export interface ApiFormValue {
	/**
	 * This should be in the following format: uuid
	 */
	fieldId: string;
	valueString: string;
	reportText: string;
	choiceIds: string[];
	presignedURL: string | null;
}
// DEFINITION: ApiInspectionDto
export interface ApiInspectionDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	assetLibrary: ApiAssetLibrary;
	forms: ApiForm[];
	formData: ApiFormRow[];
	substanceDataset: ApiSubstanceDataset | null;
	tankDataset: ApiTankDataset | null;
	mutableAssets: ApiMutableAssets;
	assetFileNames: {
		[key: string]: string;
	};
	description: string | null;
	users: ApiInspectionUserDto[];
	suggestedInspectors: ApiStudyUserDto[];
	completed: boolean;
	flags: ApiFlag[];
	scripts: ApiScript[];
	urls: ApiExternalUrl[];
	canUploadScript: boolean;
	files: ApiFile[];
	sharepointUrl: string | null;
	endNote: string | null;
	showNotAnsweredText: boolean;
}
// DEFINITION: ApiInspectionUserDto
export interface ApiInspectionUserDto {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('INSPECTION_EDITOR')[];
	globalAdmin: boolean;
	name: string | null;
	email: string;
	status: 'ACTIVE' | 'PENDING';
	removeAccess: boolean;
	allowReportDownload: boolean;
}
// DEFINITION: ApiMutableAssets
export interface ApiMutableAssets {
	assetIdsByAssetTypeId: {
		[key: string]: string[];
	};
	assetsById: {
		[key: string]: ApiAsset;
	};
}
// DEFINITION: ApiPointValue
export interface ApiPointValue {
	name: string;
	pointString: string;
	address: string | null;
}
// DEFINITION: ApiSubstance
export interface ApiSubstance {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	group: string | null;
	name: string;
	producer: string;
	sdsDate: string;
	casNumber: string | null;
	ghs: string[];
	pgsClass: string | null;
	adr: string[];
	cmrZzsClass: string[];
	type: string;
	substanceInspection: ApiSubstanceInspection;
}
// DEFINITION: ApiSubstanceDataset
export interface ApiSubstanceDataset {
	fileName: string;
	substances: {
		[key: string]: ApiSubstance;
	};
}
// DEFINITION: ApiSubstanceInspection
export interface ApiSubstanceInspection {
	storageCapacity: string;
	yearlyThroughput: string;
	intendedStorageCapacity: string;
	intendedYearlyThroughput: string;
}
// DEFINITION: ApiTank
export interface ApiTank {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	pit: string | null;
	name: string;
	new: boolean;
	substance: string;
	substanceGroup: string;
	volume: string;
	type: string;
	controlledPressure: boolean;
	pressure: string | null;
	material: string;
	controlledTemperature: boolean;
	temperature: string | null;
}
// DEFINITION: ApiTankDataset
export interface ApiTankDataset {
	fileName: string;
	tanks: ApiTank[];
}
// DEFINITION: ApiValue
export interface ApiValue {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	propertyId: string;
	/**
	 * This should be in the following format: uuid
	 */
	assetId: string;
	valueString: string;
	choiceIds: string[];
	applicable: boolean;
	other: boolean;
	locations: ApiPointValue[] | null;
	presignedURL: string | null;
}
// DEFINITION: ApiFile
export interface ApiFile {
	/**
	 * This should be in the following format: uuid
	 */
	fileId: string;
	name: string;
	processed: boolean | null;
	/**
	 * This should be in the following format: uuid
	 */
	inspection: string | null;
	/**
	 * This should be in the following format: uuid
	 */
	project: string | null;
}
// DEFINITION: ApiAuthorizedInspectionDto
export interface ApiAuthorizedInspectionDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	studyName: string;
	project: ApiProjectDto;
	description: string | null;
	users: ApiInspectionUserDto[];
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	progress: number;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	version: number;
	createdOn: string | null;
	completed: boolean;
	sharepointUrl: string | null;
}
// DEFINITION: ApiProjectDto
export interface ApiProjectDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	projectNumber: string | null;
	abwLinked: boolean;
	masterProjectId: string | null;
	companyName: string;
	createdOn: string;
}
// DEFINITION: ApiInspectionDescription
export interface ApiInspectionDescription {
	description: string;
}
// DEFINITION: ApiRelatedLinks
export interface ApiRelatedLinks {
	links: ApiExternalUrl[];
}
// DEFINITION: ApiDraftTemplate
export interface ApiDraftTemplate {
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	version: number;
	/**
	 * This should be in the following format: uuid
	 */
	studyId: string;
}
// DEFINITION: ApiPreviousTemplate
export interface ApiPreviousTemplate {
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	version: number;
	publishComment: string | null;
	changelogUrl: string | null;
	datePublished: string;
}
// DEFINITION: ApiStudyTemplates
export interface ApiStudyTemplates {
	draftTemplate: ApiDraftTemplate;
	previousTemplates: ApiPreviousTemplate[];
}
// DEFINITION: ApiPublishTemplateLibrary
export interface ApiPublishTemplateLibrary {
	changelogUrl: string;
	publishComment: string;
}
// DEFINITION: ApiUserDto
export interface ApiUserDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	email: string;
	name: string | null;
	globalAdmin: boolean;
	rhdhvUser: boolean;
}
// DEFINITION: ApiUserProfile
export interface ApiUserProfile {
	name: string | null;
	user: ApiUserDto;
	hasCreateProjectPrivilege: boolean;
	email: string;
	isGlobalAdmin: boolean;
}
// DEFINITION: ApiUpdateUserProfileDetails
export interface ApiUpdateUserProfileDetails {
	timeZoneId: string | null;
	jobTitle: string | null;
	knowledgeGroupIds: string[];
	skillsAndExpertise: string | null;
	linkedInLink: string | null;
}
// DEFINITION: ApiZoneIdWithOffset
export interface ApiZoneIdWithOffset {
	zoneId: string;
	offset: string;
}
// DEFINITION: ApiUserADFields
export interface ApiUserADFields {
	department: string | null;
	officeLocation: string | null;
}
// DEFINITION: ApiProjectUserDto
export interface ApiProjectUserDto {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('PROJECT_ADMINISTRATOR')[];
	globalAdmin: boolean;
	name: string | null;
	email: string;
}
// DEFINITION: ApiAuthorizeUserForProject
export interface ApiAuthorizeUserForProject {
	email: string;
	roles: ('PROJECT_ADMINISTRATOR')[];
}
// DEFINITION: ApiProjectUserRoleUpdate
export interface ApiProjectUserRoleUpdate {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('PROJECT_ADMINISTRATOR')[];
}
// DEFINITION: ApiRevokeProjectUserAuthorization
export interface ApiRevokeProjectUserAuthorization {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('PROJECT_ADMINISTRATOR')[];
}
// DEFINITION: ApiAuthorizeUserForStudy
export interface ApiAuthorizeUserForStudy {
	email: string;
	roles: ('STUDY_ADMINISTRATOR' | 'STUDY_SUGGESTED_INSPECTOR')[];
}
// DEFINITION: ApiStudyUserRoleUpdate
export interface ApiStudyUserRoleUpdate {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('STUDY_ADMINISTRATOR' | 'STUDY_SUGGESTED_INSPECTOR')[];
}
// DEFINITION: ApiRevokeStudyUserAuthorization
export interface ApiRevokeStudyUserAuthorization {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('STUDY_ADMINISTRATOR' | 'STUDY_SUGGESTED_INSPECTOR')[];
}
// DEFINITION: ApiAuthorizeUserForInspection
export interface ApiAuthorizeUserForInspection {
	email: string;
	roles: ('INSPECTION_EDITOR')[];
}
// DEFINITION: ApiRevokeInspectionUserAuthorization
export interface ApiRevokeInspectionUserAuthorization {
	/**
	 * This should be in the following format: uuid
	 */
	userId: string;
	roles: ('INSPECTION_EDITOR')[];
}
// DEFINITION: ApiUpdatedAtUpdate
export interface ApiUpdatedAtUpdate {
	updatedAt: string;
}
// DEFINITION: ApiRecentProjectDto
export interface ApiRecentProjectDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	companyName: string;
	projectRoles: ('PROJECT_ADMINISTRATOR')[];
	inspectionRoles: ('INSPECTION_EDITOR')[];
	activityNames: string[];
}
// DEFINITION: ApiRecentStudyDto
export interface ApiRecentStudyDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	description: string | null;
}
// DEFINITION: ApiUserProfileDetails
export interface ApiUserProfileDetails {
	email: string;
	name: string | null;
	department: string | null;
	officeLocation: string | null;
	timeZoneId: string | null;
	jobTitle: string | null;
	knowledgeGroupIds: string[];
	skillsAndExpertise: string | null;
	linkedInLink: string | null;
}
// DEFINITION: ApiUserProfileDto
export interface ApiUserProfileDto {
	userProfileDetails: ApiUserProfileDetails;
	recentProjects: ApiRecentProjectDto[];
	recentTemplates: ApiRecentStudyDto[];
}
// DEFINITION: ApiSubstanceUploadResult
export interface ApiSubstanceUploadResult {
	substanceDataset: ApiSubstanceDataset;
}
// DEFINITION: ApiTankUploadResult
export interface ApiTankUploadResult {
	tankDataset: ApiTankDataset;
}
// DEFINITION: ApiAssetUpsert
export interface ApiAssetUpsert {
	valueStringByPropertyId: {
		[key: string]: string;
	};
}
// DEFINITION: ApiValueUpsert
export interface ApiValueUpsert {
	/**
	 * This should be in the following format: uuid
	 */
	propertyId: string;
	valueString: string | null;
	noteText: string | null;
	applicable: boolean | null;
	other: boolean | null;
	location: ApiPointValue[] | null;
}
// DEFINITION: ApiAssetsByAssetTypeId
export interface ApiAssetsByAssetTypeId {
	assetsByAssetTypeId: {
		[key: string]: ApiAsset[];
	};
}
// DEFINITION: ApiFlagUpsert
export interface ApiFlagUpsert {
	/**
	 * This should be in the following format: uuid
	 */
	assetTypeId: string;
	/**
	 * This should be in the following format: uuid
	 */
	assetId: string | null;
	/**
	 * This should be in the following format: uuid
	 */
	flagAssetTypeId: string;
}
// DEFINITION: ApiChangedRuleSetDto
export interface ApiChangedRuleSetDto {
	name: string;
	rules: ApiRuleDto[];
}
// DEFINITION: ApiImageCaption
export interface ApiImageCaption {
	caption: string | null;
}
// DEFINITION: ApiAssetLibraryUpdate
export interface ApiAssetLibraryUpdate {
	mutations: ApiMutation[];
}
// DEFINITION: ApiDeleteAssetTypeMutation
export interface ApiDeleteAssetTypeMutation {
	/**
	 * This should be in the following format: uuid
	 */
	assetTypeId: string;
}
// DEFINITION: ApiDeleteCategoryMutation
export interface ApiDeleteCategoryMutation {
	/**
	 * This should be in the following format: uuid
	 */
	categoryId: string;
}
// DEFINITION: ApiDeleteChoiceMutation
export interface ApiDeleteChoiceMutation {
	/**
	 * This should be in the following format: uuid
	 */
	choiceId: string;
}
// DEFINITION: ApiDeletePropertyMutation
export interface ApiDeletePropertyMutation {
	/**
	 * This should be in the following format: uuid
	 */
	propertyId: string;
}
// DEFINITION: ApiMutation
export interface ApiMutation {
	mutationType: 'UpsertCategory' | 'UpsertAssetType' | 'UpsertProperty' | 'UpsertChoice' | 'DeleteCategory' | 'DeleteAssetType' | 'DeleteProperty' | 'DeleteChoice' | 'Action';
	categoryIds: string[] | null;
	category: ApiCategory | null;
	assetType: ApiAssetType | null;
	property: ApiProperty | null;
	choice: ApiChoice | null;
	deleteCategory: ApiDeleteCategoryMutation | null;
	deleteAssetType: ApiDeleteAssetTypeMutation | null;
	deleteProperty: ApiDeletePropertyMutation | null;
	deleteChoice: ApiDeleteChoiceMutation | null;
	actionMutation: ApiUpsertActionMutation | null;
}
// DEFINITION: ApiUpsertAction
export interface ApiUpsertAction {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	ruleId: string;
}
// DEFINITION: ApiUpsertActionMutation
export interface ApiUpsertActionMutation {
	/**
	 * This should be in the following format: uuid
	 */
	propertyId: string;
	upsertActions: ApiUpsertAction[];
}
// DEFINITION: ApiAuthorizedProjectDto
export interface ApiAuthorizedProjectDto {
	project: ApiProjectDto;
	hasAdminPrivileges: boolean;
}
// DEFINITION: ApiClientDto
export interface ApiClientDto {
	companyName: string;
	contactPersonName: string;
	contactPersonRole: string;
	contactPersonEmail: string;
	contactPersonPhoneNumber: string;
	cocNumber: string;
	websiteUrl: string;
	location: ApiClientLocationDto;
	industry: string | null;
}
// DEFINITION: ApiClientLocationDto
export interface ApiClientLocationDto {
	streetName: string;
	houseNumber: string;
	postalCode: string;
	city: string;
	country: string;
}
// DEFINITION: ApiProjectLocationDto
export interface ApiProjectLocationDto {
	streetName: string;
	houseNumber: string;
	postalCode: string;
	city: string;
	country: string;
	name: string;
}
// DEFINITION: ApiProjectUpdateDto
export interface ApiProjectUpdateDto {
	name: string;
	projectNumber: string | null;
	masterProjectId: string | null;
	location: ApiProjectLocationDto;
	client: ApiClientDto;
	templates: string[] | null;
	admins: string[] | null;
}
// DEFINITION: ApiProjectAPIError
export interface ApiProjectAPIError {
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	statusCode: number;
	message: string;
	/**
	 * This should be in the following format: uuid
	 */
	projectId: string;
	masterProjectId: string;
	cause: {
		stackTrace: ({
			classLoaderName: string | null;
			moduleName: string | null;
			moduleVersion: string | null;
			methodName: string | null;
			fileName: string | null;
			/**
			 * This number should have no decimals
			 * This should be in the following format: int32
			 */
			lineNumber: number | null;
			nativeMethod: boolean | null;
			className: string | null;
		})[] | null;
		message: string | null;
		suppressed: ({
			stackTrace: ({
				classLoaderName: string | null;
				moduleName: string | null;
				moduleVersion: string | null;
				methodName: string | null;
				fileName: string | null;
				/**
				 * This number should have no decimals
				 * This should be in the following format: int32
				 */
				lineNumber: number | null;
				nativeMethod: boolean | null;
				className: string | null;
			})[] | null;
			message: string | null;
			localizedMessage: string | null;
		})[] | null;
		localizedMessage: string | null;
	} | null;
	stackTrace: ({
		classLoaderName: string | null;
		moduleName: string | null;
		moduleVersion: string | null;
		methodName: string | null;
		fileName: string | null;
		/**
		 * This number should have no decimals
		 * This should be in the following format: int32
		 */
		lineNumber: number | null;
		nativeMethod: boolean | null;
		className: string | null;
	})[] | null;
	suppressed: ({
		stackTrace: ({
			classLoaderName: string | null;
			moduleName: string | null;
			moduleVersion: string | null;
			methodName: string | null;
			fileName: string | null;
			/**
			 * This number should have no decimals
			 * This should be in the following format: int32
			 */
			lineNumber: number | null;
			nativeMethod: boolean | null;
			className: string | null;
		})[] | null;
		message: string | null;
		localizedMessage: string | null;
	})[] | null;
	localizedMessage: string | null;
}
// DEFINITION: ApiProjectDetailsDto
export interface ApiProjectDetailsDto {
	name: string;
	projectNumber: string | null;
	masterProjectId: string | null;
	abwLinked: boolean;
	location: ApiProjectLocationDto;
	client: ApiClientDto;
	reportOptions: ApiProjectReportOptionsDto;
	isTestProject: boolean;
}
// DEFINITION: ApiInspectionAndRelatedActivityDto
export interface ApiInspectionAndRelatedActivityDto {
	studyName: string;
	description: string;
	lastAnsweredDate: string;
	reportGeneratedDate: string;
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	projectId: string;
}
// DEFINITION: ApiProjectInspectionDto
export interface ApiProjectInspectionDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	/**
	 * This should be in the following format: uuid
	 */
	studyId: string;
	/**
	 * This should be in the following format: uuid
	 */
	assetLibraryId: string;
	studyName: string;
	description: string | null;
	users: ApiInspectionUserDto[];
	suggestedInspectors: ApiStudyUserDto[];
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	progress: number;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	version: number;
	completed: boolean;
	dateCreated: string;
	deletedTemplate: boolean | null;
	sharepointUrl: string | null;
	lastAnsweredDate: string | null;
	reportGeneratedDate: string | null;
}
// DEFINITION: ApiProjectReportOptionsDto
export interface ApiProjectReportOptionsDto {
	includeCoverPage: boolean;
}
// DEFINITION: ApiViewProjectDto
export interface ApiViewProjectDto {
	inspections: ApiProjectInspectionDto[];
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	createdOn: string;
	user: ApiUserDto;
	userRole: ApiProjectUserDto | null;
	hasAdminPrivileges: boolean;
	testProject: boolean;
	projectDetails: ApiProjectDetailsDto;
	industries: string[];
	files: ApiFile[];
}
// DEFINITION: ApiViewProjectAndInspectionDto
export interface ApiViewProjectAndInspectionDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	projectName: string;
	inspections: ApiInspectionAndRelatedActivityDto[];
}
// DEFINITION: ApiProjectStudy
export interface ApiProjectStudy {
	/**
	 * This should be in the following format: uuid
	 */
	studyId: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	count: number;
}
// DEFINITION: ApiTemplateDto
export interface ApiTemplateDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	description: string | null;
}
// DEFINITION: ApiAccessStringDto
export interface ApiAccessStringDto {
	accessString: string | null;
	newUser: boolean;
}
// DEFINITION: ApiReportError
export interface ApiReportError {
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	lineNumber: number;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	column: number;
	message: string;
}
// DEFINITION: ApiMovePropertyDto
export interface ApiMovePropertyDto {
	/**
	 * This should be in the following format: uuid
	 */
	destinationAssetId: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	position: number | null;
}
// DEFINITION: ApiCreateOrUpdateProperty
export interface ApiCreateOrUpdateProperty {
	name: string;
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	type: 'STRING' | 'DECIMAL' | 'SINGLE_SELECT' | 'MULTI_SELECT' | 'SINGLE_SUBSTANCE' | 'MULTI_SUBSTANCE' | 'SINGLE_ASSET_REFERENCE' | 'MULTI_ASSET_REFERENCE' | 'IMAGE' | 'LOCATIONS' | 'DATE' | 'RICH_TEXT';
	description: string | null;
	dateFormat: string | null;
	inspection: boolean;
	choiceIds: string[] | null;
	required: boolean;
	caption: string | null;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	width: number | null;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	height: number | null;
	/**
	 * This should be in the following format: uuid
	 */
	referencedAssetType: string | null;
	/**
	 * This should be in the following format: uuid
	 */
	identifyingProperty: string | null;
	parentChoiceIds: string[];
	dependentCombinator: 'ANY' | 'ALL';
	question: string | null;
	fixed: boolean;
	allowMultipleAnswers: boolean;
	hasNotApplicableOption: boolean;
	hasOtherOption: boolean;
	survey: boolean;
	/**
	 * This should be in the following format: uuid
	 */
	stableId: string;
	placeholder: string;
}
// DEFINITION: ApiDuplicatePropertyDto
export interface ApiDuplicatePropertyDto {
	property: ApiProperty;
	choicesById: {
		[key: string]: ApiChoice;
	};
	/**
	 * This should be in the following format: uuid
	 */
	assetTypeId: string;
}
// DEFINITION: ApiMoveEntityDto
export interface ApiMoveEntityDto {
	/**
	 * This should be in the following format: uuid
	 */
	destinationEntityId: string;
}
// DEFINITION: ApiCreateFormRowForSingularAsset
export interface ApiCreateFormRowForSingularAsset {
	/**
	 * This should be in the following format: uuid
	 */
	assetTypeId: string;
	/**
	 * This should be in the following format: uuid
	 */
	formId: string;
}
// DEFINITION: ApiCreateFormRowForRepeatingAsset
export interface ApiCreateFormRowForRepeatingAsset {
	/**
	 * This should be in the following format: uuid
	 */
	assetId: string;
	/**
	 * This should be in the following format: uuid
	 */
	formId: string;
}
// DEFINITION: ApiUpsertFieldValue
export interface ApiUpsertFieldValue {
	valueString: string | null;
}
// DEFINITION: ApiCreateAssetFilter
export interface ApiCreateAssetFilter {
	/**
	 * This should be in the following format: uuid
	 */
	propertyId: string;
	checkType: 'MIN' | 'MAX' | 'EQUALS' | 'RANGE';
	checkValue: number | null;
	upperBound: number | null;
	name: string;
}
// DEFINITION: ApiAssetFilterCreated
export interface ApiAssetFilterCreated {
	/**
	 * This should be in the following format: uuid
	 */
	assetFilterId: string;
}
// DEFINITION: ApiRunResponse
export interface ApiRunResponse {
	text: string;
}
// DEFINITION: ApiScript
export interface ApiScript {
	fileName: string;
	type: 'ACTIVITY' | 'TEMPLATE';
}
// DEFINITION: ApiMasterTemplate
export interface ApiMasterTemplate {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	name: string;
	description: string | null;
	/**
	 * This should be in the following format: uuid
	 */
	createdBy: string;
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	version: number;
	sharepointUrl: string;
}
// DEFINITION: ApiQuestionSet
export interface ApiQuestionSet {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	type: 'STRING' | 'DECIMAL' | 'SINGLE_SELECT' | 'MULTI_SELECT' | 'SINGLE_SUBSTANCE' | 'MULTI_SUBSTANCE' | 'SINGLE_ASSET_REFERENCE' | 'MULTI_ASSET_REFERENCE' | 'IMAGE' | 'LOCATIONS' | 'DATE' | 'RICH_TEXT';
	question: string;
	isFileType: boolean;
}
// DEFINITION: ApiFileSet
export interface ApiFileSet {
	name: string;
	isFileType: null;
}
// DEFINITION: ApiQuestionSetResponse
export interface ApiQuestionSetResponse {
	questions: ApiQuestionSet[];
	files: ApiFileSet[];
}
// DEFINITION: ApiAssistRequest
export interface ApiAssistRequest {
	files: string[] | null;
	questions: string[] | null;
}
// DEFINITION: ApiQuestionFeedBackRequest
export interface ApiQuestionFeedBackRequest {
	files: string[] | null;
	questions: string[] | null;
	feedbackType: 'ThumbsUp' | 'ThumbsDown' | null;
	retrieverType: 'FirstFeedback' | 'SecondFeedback' | 'ThirdFeedback' | null;
}
// DEFINITION: ApiAnswerSet
export interface ApiAnswerSet {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	confidence: string[];
	/**
	 * This number should have no decimals
	 * This should be in the following format: int32
	 */
	numberOfAnswers: number;
	answer: string[];
	questionType: 'STRING' | 'DECIMAL' | 'SINGLE_SELECT' | 'MULTI_SELECT' | 'SINGLE_SUBSTANCE' | 'MULTI_SUBSTANCE' | 'SINGLE_ASSET_REFERENCE' | 'MULTI_ASSET_REFERENCE' | 'IMAGE' | 'LOCATIONS' | 'DATE' | 'RICH_TEXT';
	question: string;
	fileCitation: string;
	traceId: string;
}
// DEFINITION: ApiAssistResponse
export interface ApiAssistResponse {
	answers: ApiAnswerSet[] | null;
}
// DEFINITION: ApiEndNote
export interface ApiEndNote {
	text: string | null;
}
// DEFINITION: ApiRelatedActivityDto
export interface ApiRelatedActivityDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	inspection: null;
	/**
	 * This should be in the following format: uuid
	 */
	report: string;
}
// DEFINITION: ApiReportDto
export interface ApiReportDto {
	/**
	 * This should be in the following format: uuid
	 */
	id: string;
	reportGeneratedDate: null;
	reportRegeneratedDate: string;
}
// DEFINITION: ApiViewRelatedActivityDto
export interface ApiViewRelatedActivityDto {
	/**
	 * This should be in the following format: uuid
	 */
	relatedActivityId: string;
	inspectionId: null;
	project: ApiProjectDto;
	description: string;
	report: ApiReportDto;
	studyName: string;
	completed: boolean;
}
// ApiSecurity:
export const SecurityProviders = {
	/**
	 * Scheme: bearer
	 *
	 * @return The newly generated security token
	 */
	BearerAuth(token: string): Security<'BearerAuth', never> {
		return new HttpAuthentication('BearerAuth', token, 'Bearer');
	},
};

// API:
/**
 * KES backend API v0.0.1
 *
 * undefined
 */
export default class Api {
	private readonly baseUrl: string;
	private readonly headers: Record<string, string>;

	public constructor(baseUrl: string, headers?: Record<string, string>) {
		this.baseUrl = baseUrl;
		this.headers = headers ? headers : {};
	}

	private formatUri(uri: string, sec: ResolvedSecurity = VoidSecurity): string {
		return sec.updateUrl(new URL(uri, this.baseUrl).href);
	}
	// OPERATION: /published-studies:get
	/**
	 * Read list of all studies for the current user
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public studyListPublishedStudies(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiPublishedStudyDto[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/published-studies', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study:get
	/**
	 * Read list of all studies for the current user
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public studyListStudies(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyDto[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/study', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study:post
	/**
	 * Create new study
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public studyCreate(security: Security<'BearerAuth', never>, requestBody: ApiCreateStudyDto, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri('/study', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/labels:get
	/**
	 * Read all labels
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public studyReadLabels(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, string[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/study/labels', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/businesslines:get
	/**
	 * Read list of business lines
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public studyListBusinessLines(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, string[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/study/businesslines', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/languages:get
	/**
	 * Read list of Languagess
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public studyListLanguages(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, string[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/study/languages', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/copy:post
	/**
	 * Copy existing study
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public studyCopy(security: Security<'BearerAuth', never>, requestBody: ApiCopyStudy, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri('/study/copy', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/export:get
	/**
	 * Export template as json
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyExportTemplate(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/export`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/import:post
	/**
	 * Import template from Json file
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public studyImportTemplate(security: Security<'BearerAuth', never>, query: {studyName: string}, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/study/import${objectToQueryString(query)}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/upload:post
	/**
	 * Upload Script Study
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyUploadScript(security: Security<'BearerAuth', never>, studyId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/upload`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/end-note:post
	/**
	 * Update EndNote for Study
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyUpdateEndNote(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiEndNote, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/end-note`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/delete-script:delete
	/**
	 * Delete ScriptStudy
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyDeleteScript(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/delete-script`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}:get
	/**
	 * Read study by studyId
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyRead(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyDto> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}:put
	/**
	 * Update study by studyId
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyUpdate(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiCreateStudyDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}:delete
	/**
	 * Delete study by studyId
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyDelete(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/report-template:get
	/**
	 * Read study production report template
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyReadReportTemplate(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyReportTemplate> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/report-template`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/form:get
	/**
	 * Get study with studyid form
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public formGetStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiForm[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/form`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/form:post
	/**
	 * Post study with studyid form
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public formPostStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiCreateForm, options: Options = {}): Promise<
		FetchResponse<201, ApiForm> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/form`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			201: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/form/{formId}:put
	/**
	 * Put study with studyid form with formid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public formPutStudyWithStudyidWithid(security: Security<'BearerAuth', never>, formId: string, studyId: string, requestBody: ApiCreateForm, options: Options = {}): Promise<
		FetchResponse<200, ApiForm> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/form/${formId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/form/{formId}:delete
	/**
	 * Delete study with studyid form with formid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public formDeleteStudyWithStudyidWithid(security: Security<'BearerAuth', never>, formId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/form/${formId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/field:post
	/**
	 * Post study with studyid field
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public fieldPostStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiCreateOrUpdateField, options: Options = {}): Promise<
		FetchResponse<201, ApiField> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/field`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			201: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/field/{fieldId}:put
	/**
	 * Put study with studyid field with fieldid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public fieldPutStudyWithStudyidWithid(security: Security<'BearerAuth', never>, fieldId: string, studyId: string, requestBody: ApiCreateOrUpdateField, options: Options = {}): Promise<
		FetchResponse<200, ApiField> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/field/${fieldId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/field/{fieldId}:delete
	/**
	 * Delete study with studyid field with fieldid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public fieldDeleteStudyWithStudyidWithid(security: Security<'BearerAuth', never>, fieldId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/field/${fieldId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/field/{fieldId}/reorder:post
	/**
	 * Post study with studyid field with fieldid reorder
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public fieldPostStudyWithStudyidWithidReorder(security: Security<'BearerAuth', never>, fieldId: string, studyId: string, requestBody: ApiReOrderDto, options: Options = {}): Promise<
		FetchResponse<200, ApiField[]> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/field/${fieldId}/reorder`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/field-choice:post
	/**
	 * Post study with studyid fieldChoice
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public fieldchoicePostStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiCreateOrUpdateFieldChoice, options: Options = {}): Promise<
		FetchResponse<201, ApiFieldChoice> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/field-choice`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			201: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/field-choice/{fieldChoiceId}:put
	/**
	 * Put study with studyid fieldChoice with fieldchoiceid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public fieldchoicePutStudyWithStudyidWithid(security: Security<'BearerAuth', never>, fieldChoiceId: string, studyId: string, requestBody: ApiCreateOrUpdateFieldChoice, options: Options = {}): Promise<
		FetchResponse<200, ApiFieldChoice> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/field-choice/${fieldChoiceId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/field-choice/{fieldChoiceId}:delete
	/**
	 * Delete study with studyid fieldChoice with fieldchoiceid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public fieldchoiceDeleteStudyWithStudyidWithid(security: Security<'BearerAuth', never>, fieldChoiceId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/field-choice/${fieldChoiceId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /knowledge-groups:get
	/**
	 * Get knowledgeGroups
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public studyGetKnowledgeGroups(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiKnowledgeGroup[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/knowledge-groups', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /services:get
	/**
	 * List services
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public studyListServices(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiServiceDto[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/services', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}:get
	/**
	 * Get inspection with inspectionid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionGetWithid(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiInspectionDto> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}:delete
	/**
	 * Delete inspection with inspectionid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionDeleteWithid(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspections:get
	/**
	 * Fetch all the inspections assigned to the logged in user
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionLists(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiAuthorizedInspectionDto[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/inspections', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/description:put
	/**
	 * Persist description of project
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionPutDescription(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: ApiInspectionDescription, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/description`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/complete:put
	/**
	 * Complete inspection
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionPutComplete(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/complete`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/upload:post
	/**
	 * Upload Script
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionUploadScript(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/upload`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/delete-script:delete
	/**
	 * Delete Script
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionDeleteScript(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/delete-script`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/run:post
	/**
	 * Run Script
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionRunScript(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: ApiScript, options: Options = {}): Promise<
		FetchResponse<200, ApiRunResponse> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/run`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/externalUrls:post
	/**
	 * Add External Url to an activity
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionAddExternalUrl(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: ApiRelatedLinks, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/externalUrls`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/question-set:get
	/**
	 * Fetch Questions and Files for an activity
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionGetQuestionSetAndFiles(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiQuestionSetResponse> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/question-set`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/assist:post
	/**
	 * Assist Endpoint to connect to KAAS
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionPostAssist(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: ApiAssistRequest, options: Options = {}): Promise<
		FetchResponse<200, ApiAssistResponse> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/assist`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/feedback/traceId/{traceId}:post
	/**
	 * Feedback Endpoint to monitor in Langsmith
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionPostFeedback(security: Security<'BearerAuth', never>, traceId: string, inspectionId: string, requestBody: ApiQuestionFeedBackRequest, options: Options = {}): Promise<
		FetchResponse<200, ApiAssistResponse> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/feedback/traceId/${traceId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/templates:get
	/**
	 * List all templates for a study
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public templateLists(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyTemplates> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/templates`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/publish:post
	/**
	 * Publish the draft template library for a study
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public templatePublish(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiPublishTemplateLibrary, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyTemplates> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/publish`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /userprofile:get
	/**
	 * Get userprofile
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public userGetprofile(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiUserProfile> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/userprofile', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /user/{userId}/profile-details:get
	/**
	 * Get user with userid profileDetails
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public userGetWithidProfileDetails(security: Security<'BearerAuth', never>, userId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiUserProfileDto> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/user/${userId}/profile-details`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /user/{userId}/profile-details:put
	/**
	 * Put user with userid profileDetails
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public userPutWithidProfileDetails(security: Security<'BearerAuth', never>, userId: string, requestBody: ApiUpdateUserProfileDetails, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/user/${userId}/profile-details`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /time-zone-ids:get
	/**
	 * Get timeZoneIds
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public userGetTimeZoneIds(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiZoneIdWithOffset[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/time-zone-ids', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /user-profile-ad-fields:put
	/**
	 * Put userProfileAdFields
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public userPutProfileAdFields(security: Security<'BearerAuth', never>, requestBody: ApiUserADFields, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri('/user-profile-ad-fields', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/users:get
	/**
	 * Read the users for the project
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectuserReads(security: Security<'BearerAuth', never>, projectId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiProjectUserDto[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/users:post
	/**
	 * Authorize a user for a project (share it with them)
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public projectuserCreateProjectRole(security: Security<'BearerAuth', never>, projectId: string, requestBody: ApiAuthorizeUserForProject, options: Options = {}): Promise<
		FetchResponse<200, ApiProjectUserDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /project/{projectId}/users:put
	/**
	 * Replaces the user roles for the given project with the given roles
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectuserUpdateProjectRole(security: Security<'BearerAuth', never>, projectId: string, requestBody: ApiProjectUserRoleUpdate, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			200: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/users:delete
	/**
	 * Delete role(s) for a user in a project
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectuserDeleteProjectRole(security: Security<'BearerAuth', never>, projectId: string, requestBody: ApiRevokeProjectUserAuthorization, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/users:get
	/**
	 * Read the users for a study
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyuserReads(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyUserDto[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/users:post
	/**
	 * Authorize a user for a study (share it with them)
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public studyuserCreateStudyRole(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiAuthorizeUserForStudy, options: Options = {}): Promise<
		FetchResponse<200, ApiStudyUserDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/users:put
	/**
	 * Replaces the user roles for the given study with the given roles
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyauthorizationUpdateStudyRole(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiStudyUserRoleUpdate, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			200: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/users:delete
	/**
	 * Delete role(s) for a user in a study
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyuserDeleteStudyRole(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiRevokeStudyUserAuthorization, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/users:get
	/**
	 * Read the users for the inspection
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionuserReads(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiInspectionUserDto[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/users:post
	/**
	 * Authorize a user for a inspection (share it with them)
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public inspectionuserCreateInspectionRole(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: { file: Blob | null; email: string | null; role: 'INSPECTION_EDITOR' | null; note: string | null; removeAccess: string | null; allowReportDownload: string | null }, options: Options = {}): Promise<
		FetchResponse<200, ApiInspectionUserDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/users:delete
	/**
	 * Delete role(s) for a user in a inspection
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionuserDeleteInspectionRole(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: ApiRevokeInspectionUserAuthorization, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/users`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/builder-project:get
	/**
	 * Get study with studyid builderProject
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     503 Service Unavailable
	 */
	public builderGetStudyWithStudyidProject(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiBuilderProject> |
		FetchResponse<401, ApiError> |
		FetchResponse<503, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/builder-project`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			503: toJson,
		});
	}

	// OPERATION: /study/{studyId}/report:post
	/**
	 * Post study with studyid report
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     409 Conflict
	 */
	public builderPostStudyWithStudyidReport(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiReportTemplate, options: Options = {}): Promise<
		FetchResponse<200, ApiUpdatedAtUpdate> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<409, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/report`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			409: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/dataset/substance:post
	/**
	 * Post inspection with inspectionid dataset substance
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public datasetPostInspectionWithInspectionidSubstance(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, ApiSubstanceUploadResult> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/dataset/substance`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/dataset/substance:delete
	/**
	 * Delete inspection with inspectionid dataset substance
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public datasetDeleteInspectionWithInspectionidSubstance(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/dataset/substance`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			200: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/dataset/tank:post
	/**
	 * Post inspection with inspectionid dataset tank
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public datasetPostInspectionWithInspectionidTank(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, ApiTankUploadResult> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/dataset/tank`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/dataset/tank:delete
	/**
	 * Delete inspection with inspectionid dataset tank
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public datasetDeleteInspectionWithInspectionidTank(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/dataset/tank`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			200: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/csv/{assetTypeId}:post
	/**
	 * Post inspection with inspectionid csv with assettypeid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public datasetPostInspectionWithInspectionidCsvWithAssettypeid(security: Security<'BearerAuth', never>, assetTypeId: string, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, ApiAsset[]> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/csv/${assetTypeId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/asset-type/{assetTypeId}/asset/{assetId}:post
	/**
	 * Post inspection with inspectionid assetType with assettypeid asset with assetid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetPostInspectionWithInspectionidTypeWithtypeidWithid(security: Security<'BearerAuth', never>, assetId: string, assetTypeId: string, inspectionId: string, requestBody: ApiAssetUpsert, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/asset-type/${assetTypeId}/asset/${assetId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/asset-type/{assetTypeId}/asset/{assetId}:delete
	/**
	 * Delete inspection with inspectionid assetType with assettypeid asset with assetid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetDeleteInspectionWithInspectionidTypeWithtypeidWithid(security: Security<'BearerAuth', never>, assetId: string, assetTypeId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/asset-type/${assetTypeId}/asset/${assetId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/answer-singular-asset:post
	/**
	 * Post inspection with inspectionid answerSingularAsset
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetPostInspectionWithInspectionidAnswerSingular(security: Security<'BearerAuth', never>, inspectionId: string, requestBody: ApiValueUpsert, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/answer-singular-asset`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/answer-repeating-asset/{assetId}:post
	/**
	 * Post inspection with inspectionid answerRepeatingAsset with assetid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetPostInspectionWithInspectionidAnswerRepeatingWithid(security: Security<'BearerAuth', never>, assetId: string, inspectionId: string, requestBody: ApiValueUpsert, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/answer-repeating-asset/${assetId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/asset-by-id:get
	/**
	 * Get inspection with inspectionid assetById
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public assetGetInspectionWithInspectionidById(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiAssetsByAssetTypeId> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/asset-by-id`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/asset/{assetId}/property/{propertyId}/active:get
	/**
	 * Get inspection with inspectionid asset with assetid property with propertyid active
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetGetInspectionWithInspectionidWithidPropertyWithPropertyidActive(security: Security<'BearerAuth', never>, propertyId: string, assetId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, boolean> |
		FetchResponse<400, boolean> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, boolean>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/asset/${assetId}/property/${propertyId}/active`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/asset-type/{assetTypeId}/asset:delete
	/**
	 * Delete inspection with inspectionid assetType with assettypeid asset
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assetDeleteInspectionWithInspectionidTypeWithtypeid(security: Security<'BearerAuth', never>, assetTypeId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/asset-type/${assetTypeId}/asset`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/flag-asset/{flagAssetId}/flag/{flagId}:post
	/**
	 * Post inspection with inspectionid flagAsset with flagassetid flag with flagid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetPostInspectionWithInspectionidFlagWithFlagidFlagWithFlagid(security: Security<'BearerAuth', never>, flagId: string, flagAssetId: string, inspectionId: string, requestBody: ApiFlagUpsert, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/flag-asset/${flagAssetId}/flag/${flagId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/ruleset:get
	/**
	 * List study rule sets
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public studyListRuleSets(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiRuleSetDto[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/ruleset`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/ruleset/{ruleSetId}:put
	/**
	 * Create or update a rule set of a study
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public studyCreateOrUpdateRuleSet(security: Security<'BearerAuth', never>, ruleSetId: string, studyId: string, requestBody: ApiChangedRuleSetDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/ruleset/${ruleSetId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/ruleset/{ruleSetId}:delete
	/**
	 * Delete a rule set of a study
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public studyDeleteRuleSet(security: Security<'BearerAuth', never>, ruleSetId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/ruleset/${ruleSetId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/file/{fileId}:post
	/**
	 * Post inspection with inspectionid property with propertyid file
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfilePostInspectionWithInspectionidPropertyWithPropertyidFile(security: Security<'BearerAuth', never>, fileId: string, propertyId: string, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/file/${fileId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/file/{fileId}:delete
	/**
	 * Delete inspection with inspectionid property with propertyid file
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfileDeleteInspectionWithInspectionidPropertyWithPropertyidFile(security: Security<'BearerAuth', never>, fileId: string, propertyId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/file/${fileId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/asset/{assetId}/file/{fileId}:post
	/**
	 * Post inspection with inspectionid property with propertyid asset with assetid file
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfilePostInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetidFile(security: Security<'BearerAuth', never>, fileId: string, assetId: string, propertyId: string, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/asset/${assetId}/file/${fileId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/asset/{assetId}/file/{fileId}:delete
	/**
	 * Delete inspection with inspectionid property with propertyid asset with assetid file
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfileDeleteInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetidFile(security: Security<'BearerAuth', never>, fileId: string, assetId: string, propertyId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/asset/${assetId}/file/${fileId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/file/{fileId}:delete
	/**
	 * Delete inspection with inspectionid file with fileid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfileDeleteInspectionWithInspectionidWithFileId(security: Security<'BearerAuth', never>, fileId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/file/${fileId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /file/{fileId}:get
	/**
	 * Get inspection with inspectionid property with propertyid file
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfileGetFileWithProjectOrInspection(security: Security<'BearerAuth', never>, fileId: string, query: {projectId?: string, inspectionId?: string}, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/file/${fileId}${objectToQueryString(query)}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /file/{fileId}:delete
	/**
	 * Delete Related File for Question, Activity, Project
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfileDeleteFilewithQuestionOrActivityOrProject(security: Security<'BearerAuth', never>, fileId: string, query: {projectId?: string, inspectionId?: string, assetId?: string, propertyId?: string}, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/file/${fileId}${objectToQueryString(query)}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /file:post
	/**
	 * Post inspection with inspectionid property with propertyid file
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public relatedfilePostFileWithProjectOrInspectionOrProperty(security: Security<'BearerAuth', never>, query: {projectId?: string, inspectionId?: string, assetId?: string, propertyId?: string}, requestBody: { files: Blob | null; fileIds: string | null }, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/file${objectToQueryString(query)}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/image:get
	/**
	 * Get inspection with inspectionid property with propertyid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imageGetInspectionWithInspectionidPropertyWithPropertyid(security: Security<'BearerAuth', never>, propertyId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/image:post
	/**
	 * Post inspection with inspectionid property with propertyid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imagePostInspectionWithInspectionidPropertyWithPropertyid(security: Security<'BearerAuth', never>, propertyId: string, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/image:delete
	/**
	 * Delete inspection with inspectionid property with propertyid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imageDeleteInspectionWithInspectionidPropertyWithPropertyid(security: Security<'BearerAuth', never>, propertyId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/asset/{assetId}/image:get
	/**
	 * Get inspection with inspectionid property with propertyid asset with assetid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imageGetInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetid(security: Security<'BearerAuth', never>, assetId: string, propertyId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/asset/${assetId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/asset/{assetId}/image:post
	/**
	 * Post inspection with inspectionid property with propertyid asset with assetid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imagePostInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetid(security: Security<'BearerAuth', never>, assetId: string, propertyId: string, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/asset/${assetId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/asset/{assetId}/image:delete
	/**
	 * Delete inspection with inspectionid property with propertyid asset with assetid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imageDeleteInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetid(security: Security<'BearerAuth', never>, assetId: string, propertyId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/asset/${assetId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/imageCaption:post
	/**
	 * Post inspection with inspectionid property with propertyid imagecaption
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imagePostInspectionWithInspectionidPropertyWithPropertyidcaption(security: Security<'BearerAuth', never>, propertyId: string, inspectionId: string, requestBody: ApiImageCaption, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/imageCaption`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/property/{propertyId}/asset/{assetId}/imageCaption:post
	/**
	 * Post inspection with inspectionid property with propertyid asset with assetid imagecaption
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public imagePostInspectionWithInspectionidPropertyWithPropertyidAssetWithAssetidcaption(security: Security<'BearerAuth', never>, assetId: string, propertyId: string, inspectionId: string, requestBody: ApiImageCaption, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/property/${propertyId}/asset/${assetId}/imageCaption`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/row/{rowId}/field/{fieldId}/image:get
	/**
	 * Get inspection with inspectionid row with rowid field with fieldid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public fieldimageGetInspectionWithInspectionidRowWithRowidFieldWithFieldidImage(security: Security<'BearerAuth', never>, fieldId: string, rowId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/row/${rowId}/field/${fieldId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/row/{rowId}/field/{fieldId}/image:post
	/**
	 * Post inspection with inspectionid row with rowid field with fieldid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public fieldimagePostInspectionWithInspectionidRowWithRowidFieldWithFieldidImage(security: Security<'BearerAuth', never>, fieldId: string, rowId: string, inspectionId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/row/${rowId}/field/${fieldId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/row/{rowId}/field/{fieldId}/image:delete
	/**
	 * Delete inspection with inspectionid row with rowid field with fieldid image
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public fieldimageDeleteInspectionWithInspectionidRowWithRowidFieldWithFieldidImage(security: Security<'BearerAuth', never>, fieldId: string, rowId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/row/${rowId}/field/${fieldId}/image`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-library:get
	/**
	 * Get study with studyid assetLibrary
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public assetlibraryGetStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiAssetLibrary> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-library`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-library:post
	/**
	 * Post study with studyid assetLibrary
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assetlibraryPostStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiAssetLibraryUpdate, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-library`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-library/{assetLibraryId}:post
	/**
	 * Post study with studyid assetLibrary with assetlibraryid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetlibraryPostStudyWithStudyidWithid(security: Security<'BearerAuth', never>, assetLibraryId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-library/${assetLibraryId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project:get
	/**
	 * Get project
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectGet(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiAuthorizedProjectDto[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/project', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project:post
	/**
	 * Post project
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 *     409 Conflict
	 */
	public projectPost(security: Security<'BearerAuth', never>, requestBody: ApiProjectUpdateDto, options: Options = {}): Promise<
		FetchResponse<200, ApiAuthorizedProjectDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError> |
		FetchResponse<409, ApiProjectAPIError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri('/project', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
			409: toJson,
		});
	}

	// OPERATION: /project/{projectId}/details:put
	/**
	 * Put project with projectid details
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 *     409 Conflict
	 */
	public projectPutWithidDetails(security: Security<'BearerAuth', never>, projectId: string, requestBody: ApiProjectUpdateDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError> |
		FetchResponse<409, ApiProjectAPIError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/details`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
			409: toJson,
		});
	}

	// OPERATION: /project/industries:get
	/**
	 * Get project industries
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 */
	public projectGetIndustries(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, string[]> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/project/industries', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
		});
	}

	// OPERATION: /project/{projectId}:get
	/**
	 * Get project with projectid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 *     503 Service Unavailable
	 */
	public projectGetWithid(security: Security<'BearerAuth', never>, projectId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiViewProjectDto> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError> |
		FetchResponse<503, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
			503: toJson,
		});
	}

	// OPERATION: /project/{projectId}:delete
	/**
	 * Delete project with projectid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectDeleteWithid(security: Security<'BearerAuth', never>, projectId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/download-reports:get
	/**
	 * Get project with projectid downloadReports
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectGetWithidDownloadReports(security: Security<'BearerAuth', never>, projectId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/download-reports`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/inspections:post
	/**
	 * Add inspections to a project
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectAddInspectionsTo(security: Security<'BearerAuth', never>, projectId: string, requestBody: ApiProjectStudy[], options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/inspections`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/report-settings:put
	/**
	 * Put project with projectid reportSettings
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectPutWithidReportSettings(security: Security<'BearerAuth', never>, projectId: string, requestBody: ApiProjectReportOptionsDto, options: Options = {}): Promise<
		FetchResponse<200, ApiProjectReportOptionsDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/report-settings`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /subjects:get
	/**
	 * Get subjects
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectGetSubjects(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiSubject[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/subjects', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /subject/{subjectId}/template:get
	/**
	 * Get subject with subjectid template
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectGetSubjectWithSubjectidTemplate(security: Security<'BearerAuth', never>, subjectId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiTemplateDto[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/subject/${subjectId}/template`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/token:get
	/**
	 * Get project with projectid token
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectGetWithidToken(security: Security<'BearerAuth', never>, projectId: string, options: Options = {}): Promise<
		FetchResponse<200, string> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/token`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/copy:post
	/**
	 * Post project with projectid copy
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectPostWithidCopy(security: Security<'BearerAuth', never>, projectId: string, query: {includeActivities?: boolean}, options: Options = {}): Promise<
		FetchResponse<200, ApiAuthorizedProjectDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/copy${objectToQueryString(query)}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/inspection/{inspectionId}/copy:post
	/**
	 * Post project with projectid study with studyid version with version copy
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectPostWithidStudyWithStudyidVersionWithVersionCopy(security: Security<'BearerAuth', never>, inspectionId: string, projectId: string, query: {withAnswers?: boolean}, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/inspection/${inspectionId}/copy${objectToQueryString(query)}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /project/{projectId}/accessString:get
	/**
	 * Get project with projectid accessstring
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public projectGetWithidAccessstring(security: Security<'BearerAuth', never>, projectId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiAccessStringDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/project/${projectId}/accessString`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/download-data:get
	/**
	 * Get excel report of the data
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public inspectionExcelDownload(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/download-data`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/report/download:post
	/**
	 * Get word download of current report
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public reportWordDownload(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/report/download`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/report/download/experimental:post
	/**
	 * Get odt download of current report
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public reportOdtDownload(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/report/download/experimental`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/report/download/html:post
	/**
	 * Get odt download of current report
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public reportHtmlDownload(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/report/download/html`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/report/validate:get
	/**
	 * Validate report template
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public reportValidate(security: Security<'BearerAuth', never>, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiReportError[]> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/report/validate`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/property/{propertyId}:post
	/**
	 * Post study with studyid property with propertyid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public propertyPostStudyWithStudyidWithid(security: Security<'BearerAuth', never>, propertyId: string, studyId: string, requestBody: ApiReOrderDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/property/${propertyId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/property/{propertyId}:delete
	/**
	 * Delete study with studyid property with propertyid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public propertyDeleteStudyWithStudyidWithid(security: Security<'BearerAuth', never>, propertyId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/property/${propertyId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/property/{propertyId}/move:post
	/**
	 * Post study with studyid property with propertyid move
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public propertyPostStudyWithStudyidWithidMove(security: Security<'BearerAuth', never>, propertyId: string, studyId: string, requestBody: ApiMovePropertyDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/property/${propertyId}/move`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-type/{assetTypeId}/property:post
	/**
	 * Post study with studyid assetType with assettypeid property
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public propertyPostStudyWithStudyidAssetTypeWithAssettypeid(security: Security<'BearerAuth', never>, assetTypeId: string, studyId: string, requestBody: ApiProperty, options: Options = {}): Promise<
		FetchResponse<201, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-type/${assetTypeId}/property`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			201: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/property:put
	/**
	 * Put study with studyid property
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public propertyPutStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiCreateOrUpdateProperty, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/property`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/property/{propertyId}/duplicate:post
	/**
	 * Post study with studyid property with propertyid duplicate
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public propertyPostStudyWithStudyidWithidDuplicate(security: Security<'BearerAuth', never>, propertyId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<200, ApiDuplicatePropertyDto> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/property/${propertyId}/duplicate`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /study/{studyId}/category/{categoryId}:post
	/**
	 * Post study with studyid category with categoryid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public categoryPostStudyWithStudyidWithid(security: Security<'BearerAuth', never>, categoryId: string, studyId: string, requestBody: ApiReOrderDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/category/${categoryId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/category/{categoryId}:delete
	/**
	 * Delete study with studyid category with categoryid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public categoryDeleteStudyWithStudyidWithid(security: Security<'BearerAuth', never>, categoryId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/category/${categoryId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/assetLibrary/{assetLibraryId}/category:post
	/**
	 * Post study with studyid assetlibrary with assetlibraryid category
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public categoryPostStudyWithStudyidAssetlibraryWithAssetlibraryid(security: Security<'BearerAuth', never>, assetLibraryId: string, studyId: string, requestBody: ApiCategory, options: Options = {}): Promise<
		FetchResponse<201, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/assetLibrary/${assetLibraryId}/category`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			201: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/category:put
	/**
	 * Put study with studyid category
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public categoryPutStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiCategory, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/category`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-type/{assetTypeId}:post
	/**
	 * Post study with studyid assetType with assettypeid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assettypePostStudyWithStudyidWithid(security: Security<'BearerAuth', never>, assetTypeId: string, studyId: string, requestBody: ApiReOrderDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-type/${assetTypeId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-type/{assetTypeId}:delete
	/**
	 * Delete study with studyid assetType with assettypeid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assettypeDeleteStudyWithStudyidWithid(security: Security<'BearerAuth', never>, assetTypeId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-type/${assetTypeId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/category/{categoryId}/asset-type:post
	/**
	 * Post study with studyid category with categoryid assetType
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assettypePostStudyWithStudyidCategoryWithCategoryid(security: Security<'BearerAuth', never>, categoryId: string, studyId: string, requestBody: ApiAssetType, options: Options = {}): Promise<
		FetchResponse<201, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/category/${categoryId}/asset-type`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			201: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-type:put
	/**
	 * Put study with studyid assetType
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assettypePutStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiAssetType, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-type`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/asset-type/{assetTypeId}/move:post
	/**
	 * Post study with studyid assetType with assettypeid move
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assettypePostStudyWithStudyidWithidMove(security: Security<'BearerAuth', never>, assetTypeId: string, studyId: string, requestBody: ApiMoveEntityDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/asset-type/${assetTypeId}/move`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/choice/{choiceId}:post
	/**
	 * Post study with studyid choice with choiceid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public choicePostStudyWithStudyidWithid(security: Security<'BearerAuth', never>, choiceId: string, studyId: string, requestBody: ApiReOrderDto, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/choice/${choiceId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/choice/{choiceId}:delete
	/**
	 * Delete study with studyid choice with choiceid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public choiceDeleteStudyWithStudyidWithid(security: Security<'BearerAuth', never>, choiceId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/choice/${choiceId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/property/{propertyId}/choice:post
	/**
	 * Post study with studyid property with propertyid choice
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public choicePostStudyWithStudyidPropertyWithPropertyid(security: Security<'BearerAuth', never>, propertyId: string, studyId: string, requestBody: ApiChoice, options: Options = {}): Promise<
		FetchResponse<201, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/property/${propertyId}/choice`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			201: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/choice:put
	/**
	 * Put study with studyid choice
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public choicePutStudyWithStudyid(security: Security<'BearerAuth', never>, studyId: string, requestBody: ApiChoice, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/choice`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/action/{actionId}/dependent-property/{propertyId}:put
	/**
	 * Put study with studyid action with actionid dependentProperty with propertyid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public actionPutStudyWithStudyidWithidDependentPropertyWithPropertyid(security: Security<'BearerAuth', never>, propertyId: string, actionId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/action/${actionId}/dependent-property/${propertyId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/action/{actionId}/dependent-property/{propertyId}:delete
	/**
	 * Delete study with studyid action with actionid dependentProperty with propertyid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public actionDeleteStudyWithStudyidWithidDependentPropertyWithPropertyid(security: Security<'BearerAuth', never>, propertyId: string, actionId: string, studyId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/action/${actionId}/dependent-property/${propertyId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/row/{rowId}/singular-asset:put
	/**
	 * Put inspection with inspectionid row with rowid singularAsset
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public rowPutInspectionWithInspectionidWithidSingularAsset(security: Security<'BearerAuth', never>, rowId: string, inspectionId: string, requestBody: ApiCreateFormRowForSingularAsset, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/row/${rowId}/singular-asset`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/row/{rowId}/repeating-asset:put
	/**
	 * Put inspection with inspectionid row with rowid repeatingAsset
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public rowPutInspectionWithInspectionidWithidRepeatingAsset(security: Security<'BearerAuth', never>, rowId: string, inspectionId: string, requestBody: ApiCreateFormRowForRepeatingAsset, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/row/${rowId}/repeating-asset`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/row/{rowId}:delete
	/**
	 * Delete inspection with inspectionid row with rowid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public rowDeleteInspectionWithInspectionidWithid(security: Security<'BearerAuth', never>, rowId: string, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/row/${rowId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/row/{rowId}/field/{fieldId}/upsert-value:put
	/**
	 * Put inspection with inspectionid row with rowid field with fieldid upsertValue
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public rowPutInspectionWithInspectionidWithidFieldWithFieldidUpsertValue(security: Security<'BearerAuth', never>, fieldId: string, rowId: string, inspectionId: string, requestBody: ApiUpsertFieldValue, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/row/${rowId}/field/${fieldId}/upsert-value`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /asset-filter:post
	/**
	 * Post assetFilter
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     401 Unauthorized
	 */
	public assetfilterPost(security: Security<'BearerAuth', never>, requestBody: ApiCreateAssetFilter, options: Options = {}): Promise<
		FetchResponse<200, ApiAssetFilterCreated> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri('/asset-filter', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /asset-filter/{assetFilterId}:put
	/**
	 * Put assetFilter with assetfilterid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetfilterPutWithid(security: Security<'BearerAuth', never>, assetFilterId: string, requestBody: ApiCreateAssetFilter, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;
		const body = JSON.stringify(requestBody);

		return apiRequest(new Request(this.formatUri(`/asset-filter/${assetFilterId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				'content-type': 'application/json',
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			204: toResponse,
			400: toJson,
			401: toJson,
		});
	}

	// OPERATION: /asset-filter/{assetFilterId}:delete
	/**
	 * Delete assetFilter with assetfilterid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public assetfilterDeleteWithid(security: Security<'BearerAuth', never>, assetFilterId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/asset-filter/${assetFilterId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
		});
	}

	// OPERATION: /study/{studyId}/image/{imageId}:post
	/**
	 * Post study with studyid image with imageid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public templateimagePostStudyWithStudyidImageWithImageid(security: Security<'BearerAuth', never>, imageId: string, studyId: string, requestBody: { file: Blob | null }, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/study/${studyId}/image/${imageId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /image/{imageId}:get
	/**
	 * Get image with imageid
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public templateimageGetImageWithImageid(security: Security<'BearerAuth', never>, imageId: string, options: Options = {}): Promise<
		FetchResponse<200, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/image/${imageId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /inspection/{inspectionId}/clear:delete
	/**
	 * Delete assets and Images with inspectionid
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     400 Bad Request
	 *     404 Not Found
	 */
	public assetClearInspection(security: Security<'BearerAuth', never>, inspectionId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<400, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/inspection/${inspectionId}/clear`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			400: toJson,
			404: toJson,
		});
	}

	// OPERATION: /master-template:get
	/**
	 * Read list of all available Master Templates
	 *
	 * @return The response to the request:
	 *     200 OK
	 */
	public mastertemplateLists(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiMasterTemplate[]>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/master-template', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
		});
	}

	// OPERATION: /master-template:post
	/**
	 * Create new Master Template
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 */
	public mastertemplateCreate(security: Security<'BearerAuth', never>, requestBody: { file: Blob | null; name: string | null; description: string | null }, options: Options = {}): Promise<
		FetchResponse<200, ApiMasterTemplate> |
		FetchResponse<400, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri('/master-template', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
		});
	}

	// OPERATION: /master-template/{masterTemplateId}:put
	/**
	 * Update Master Template by masterTemplateId
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public mastertemplateUpdate(security: Security<'BearerAuth', never>, masterTemplateId: string, requestBody: { file: Blob | null; name: string | null; description: string | null }, options: Options = {}): Promise<
		FetchResponse<200, ApiMasterTemplate> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;
		const body = new FormData();
		for (const [key, value] of Object.entries(requestBody)) {
			if (value !== null) {
				body.append(key, value);
			}
		}

		return apiRequest(new Request(this.formatUri(`/master-template/${masterTemplateId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			body,
			method: 'put',
		}), {
			200: toJson,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /master-template/{masterTemplateId}:delete
	/**
	 * Delete MasterTemplate For Id
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     401 Unauthorized
	 *     404 Not Found
	 */
	public mastertemplateDelete(security: Security<'BearerAuth', never>, masterTemplateId: string, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<401, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/master-template/${masterTemplateId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			401: toJson,
			404: toJson,
		});
	}

	// OPERATION: /activity/{inspectionId}/relatedActivity/{relatedActivityId}:post
	/**
	 * Create Related Activity for Activity
	 *
	 * @return The response to the request:
	 *     201 Created
	 *     400 Bad Request
	 *     404 Not Found
	 */
	public relatedactivityCreate(security: Security<'BearerAuth', never>, relatedActivityId: string | null, inspectionId: string | null, options: Options = {}): Promise<
		FetchResponse<201, ApiRelatedActivityDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/activity/${inspectionId}/relatedActivity/${relatedActivityId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			201: toJson,
			400: toJson,
			404: toJson,
		});
	}

	// OPERATION: /activity/{inspectionId}/relatedActivity/{relatedActivityId}:delete
	/**
	 * Delete Related Activity Data.
	 *
	 * @return The response to the request:
	 *     204 No Content
	 *     404 Not Found
	 */
	public relatedactivityDelete(security: Security<'BearerAuth', never>, relatedActivityId: string | null, inspectionId: string | null, options: Options = {}): Promise<
		FetchResponse<204, Response> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/activity/${inspectionId}/relatedActivity/${relatedActivityId}`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'delete',
		}), {
			204: toResponse,
			404: toJson,
		});
	}

	// OPERATION: /activity/{inspectionId}/related-activities:get
	/**
	 * Get RelatedActivity with inspectionId
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     404 Not Found
	 */
	public relatedactivityGet(security: Security<'BearerAuth', never>, inspectionId: string | null, options: Options = {}): Promise<
		FetchResponse<200, ApiViewRelatedActivityDto[]> |
		FetchResponse<400, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/activity/${inspectionId}/related-activities`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			400: toJson,
			404: toJson,
		});
	}

	// OPERATION: /activity/{inspectionId}/relatedActivity/{relatedActivityId}/regenerate:post
	/**
	 * Regenerate the Report and Update Related Activity.
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     400 Bad Request
	 *     404 Not Found
	 */
	public relatedactivityRegenerateReport(security: Security<'BearerAuth', never>, relatedActivityId: string | null, inspectionId: string | null, options: Options = {}): Promise<
		FetchResponse<200, ApiRelatedActivityDto> |
		FetchResponse<400, ApiError> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri(`/activity/${inspectionId}/relatedActivity/${relatedActivityId}/regenerate`, sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'post',
		}), {
			200: toJson,
			400: toJson,
			404: toJson,
		});
	}

	// OPERATION: /projectAndInspection:get
	/**
	 * Get all projects and activities user can access
	 *
	 * @return The response to the request:
	 *     200 OK
	 *     404 Not Found
	 */
	public projectListsAndInspections(security: Security<'BearerAuth', never>, options: Options = {}): Promise<
		FetchResponse<200, ApiViewProjectAndInspectionDto[]> |
		FetchResponse<404, ApiError>
	> {
		const sec = security;

		return apiRequest(new Request(this.formatUri('/projectAndInspection', sec), {
			headers: sec.updateHeaders({
				...this.headers,
				...options.headers,
			}),
			cache: options.cache,
			method: 'get',
		}), {
			200: toJson,
			404: toJson,
		});
	}

}
// THIS IS A GENERATED FILE, DO NOT MODIFY
